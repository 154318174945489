module.exports = [{
      plugin: require('../plugins/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/edx/app/prospectus/prospectus/src/i18n/messages","languages":["es"],"defaultLanguage":"en","fallbackLanguage":"en","redirect":false,"disableTranslated404":true,"useNameForId":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"FEoVnr2DcBBhH0b5eauyVVK1PwrxQ0CU","devKey":"FEoVnr2DcBBhH0b5eauyVVK1PwrxQ0CU","trackPage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
